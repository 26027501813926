import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
    PieChartOutlined,
    AreaChartOutlined,
    ProductOutlined,
    TeamOutlined,
    TrophyOutlined,
    TagOutlined
} from '@ant-design/icons';

const MenuReport = () => {
    const location = useLocation();

    const getActiveClass = (path) => {
        return location.pathname === path ? 'item-statistic-selected' : '';
    }

    return (
        <div className='page-statistic-menu'>
            <span className="title-statistic">Thống kê</span>
            <Link to="/report" className={`item-statistic ${getActiveClass('/report')} ${getActiveClass('/report/')}`}>
                <PieChartOutlined />
                <span className="setting-title">Tổng quan</span>
            </Link>
            <Link to="/report/page" className={`item-statistic ${getActiveClass('/report/page')}`}>
                <ProductOutlined />
                <span className="setting-title">Trang</span>
            </Link>
            <Link to="/report/session" className={`item-statistic ${getActiveClass('/report/session')}`}>
                <AreaChartOutlined />
                <span className="setting-title">Cuộc hội thoại</span>
            </Link>
            <Link to="/report/staff" className={`item-statistic ${getActiveClass('/report/staff')}`}>
                <TeamOutlined />
                <span className="setting-title">Nhân viên</span>
            </Link>
            <Link to="/report/customer" className={`item-statistic ${getActiveClass('/report/customer')}`}>
                <TrophyOutlined />
                <span className="setting-title">Khách hàng</span>
            </Link>
            <Link to="/report/tag" className={`item-statistic ${getActiveClass('/report/tag')}`}>
                <TagOutlined />
                <span className="setting-title">Thẻ hội thoại</span>
            </Link>
        </div>
    );
};

export default MenuReport;
