import React, {useState, useEffect, useContext} from "react";
import './CustomerInfo.sass';
import './ButtonDirect.sass';
import 'bootstrap/dist/css/bootstrap.min.css';
import Config from '../../../config';
import {useGlobal} from "reactn";
import {FiEdit, FiSearch} from "react-icons/fi";
import {Image, Tooltip, OverlayTrigger} from "react-bootstrap";
import SearchBar from "./SearchBar";
import SearchPage from "./SearchPage";
import Loading from "./Loading";
import {useSelector} from "react-redux";
import store from "../../../store";
import updateChatNotification from "../../../actions/updateChatNotification";
import removeLinkCustomer from "../../../actions/removeLinkCustomer";
import SweetAlert from "react-bootstrap-sweetalert";
import icons from "../../../assets"
import Actions from "../../../constants/Actions";
import getRooms from "../../../actions/getRooms";
import {useLocation} from "react-router-dom";
import { AppContext } from '../../../context/AppContext';
import EditUserModal from "./EditUserModal";
import { Tag, Popover } from 'antd';
import { FiPhone } from "react-icons/fi";

const CustomerInfo = ({userChat, setShowAlert, showAlertAction, hideAlertAction, detectCustomerAction, loadingCustomerInfo, customerInfo, isDetectCustomer, customerName, setCustomerName}) => {
    const room = useSelector(state => state.io.room);
    const brand = useGlobal('brand')[0];
    const location = useLocation();

    const { i18n } = useContext(AppContext);

    //Filter
    const roomFilter = useGlobal('roomFilter')[0];
    
    const setSearchPage = useGlobal('searchPage')[1];
    
    const [searchResultCustomer, setSearchResultCustomer] = useState([]);
    const [loading, setLoading] = useState(false);

    const [showModalEditUser, setShowModalEditUser] = useState(false);

    useEffect(() => {
        window.addEventListener('message', function (event) {
            var data = event.data;
            if(data.func === 'editSuccessCustomerLead'){
                editSuccessCustomerLead(data.message);
            } else if(data.func === 'editSuccessCustomer'){
                editSuccessCustomer(data.message);
            }

            setLoading(false);
        });
    }, []);

    useEffect(() => {
        setSearchResultCustomer([]);
        sessionStorage.setItem('room_id', room._id);

    }, [room, userChat]);

    const reloadRooms = () => {
        getRooms(roomFilter)
            .then(res => store.dispatch({type: Actions.SET_ROOMS, rooms: res.data.rooms}))
            .catch(err => console.log(err));
    }

    const resetCustomer = () => {
        userChat.customer_id = null;
        userChat.customer_lead_id = null;
        userChat.phone = null;
        userChat.code = null;
        userChat.lead_code = null;
        userChat.email = null;
        userChat.address = null;
        userChat.type = null;
    }

    const Picture = ({room, userChat}) => {
        if (room && room.shieldedID)
            return <img src={`${Config.url || ''}/api/images/${room.shieldedID}/256/${brand}`} alt="avatar" />;
        else
            return <div className="img">{`${userChat.firstName.substr(0,1)}`}</div>;
    };

    const handleSetLoading = () => {
        setLoading(true);

        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }

    // Xóa liên kết KH
    const handleRemoveLinkCustomer = () => {
        handleSetLoading(true);
        removeLinkCustomer({
            user_id: userChat._id,
            customer_id: customerInfo.customer_id, 
            customer_lead_id: customerInfo.customer_lead_id
        }).then(res => {
            setLoading(false);
            if(res.data.error === 0){
                detectCustomerAction(userChat._id);
                resetCustomer();
                reloadRooms();
                showAlertAction('success', res.data.message);
            }
            else{
                showAlertAction('warning', res.data.message);
            }
        }).catch(err => showAlertAction('warning', err));


        //post qua epoints clear data
        window.parent.postMessage({
            'func': 'unlinkChCustomer',
            'message': {
                ch_customer_id : customerInfo.mapping_id,
                full_name : customerName
            }
        }, "*");
    }

    // Hiển thị confirm
    const showConfirmAction = (message, title = '') => {
        // handleSetLoading(true);
        setShowAlert(<SweetAlert 
            warning
            showCancel
            confirmBtnText={i18n.t('confirm')} 
            cancelBtnText={i18n.t('cancel')} 
            confirmBtnBsStyle="danger"
            title={title}
            btnSize="sm" 
            onConfirm={() => handleRemoveLinkCustomer()} 
            onCancel={() => hideAlertAction()}>
                {message}
            </SweetAlert>
        )
    }

    const actionEditCustomer = () => {
        if(customerInfo && customerInfo.customer_id){
            showEditCustomer();
        }
        else{
            showEditCustomerLead();
        }
    }

    const actionEditUserChat = () => {
        console.log(1);
        setShowModalEditUser(true);
    }

    const showEditCustomer = () => {
        handleSetLoading(true);
        window.parent.postMessage({
            'func': 'showEditCustomer',
            'message': {
                customer_id : customerInfo.customer_id
            }
        }, "*");

        var count = 0;

        //Cheat reload customer info when edit
        var intervalID = setInterval(function () {
            let chatUser = JSON.parse(sessionStorage.getItem('user_chat'));
            detectCustomerAction(chatUser._id);

            if (++count === 1) {
                window.clearInterval(intervalID);
            }
        }, 7000);
    }

    const showEditCustomerLead = () => {
        handleSetLoading(true);
        window.parent.postMessage({
            'func': 'showEditCustomerLead',
            'message': {
                customer_lead_id : customerInfo.customer_lead_id
            }
        }, "*");
    }

    const editSuccessCustomerLead = (data) => {
        console.log('editSuccessCustomerLead', data);
        let chatUser = JSON.parse(sessionStorage.getItem('user_chat'));
        if(data){
            detectCustomerAction(chatUser._id);
        }
    }

    const editSuccessCustomer = (data) => {
        console.log('editSuccessCustomer', data);
        let chatUser = JSON.parse(sessionStorage.getItem('user_chat'));
        if(data){
            detectCustomerAction(chatUser._id);
        }
    }

    // View customer detail
    const viewDetailCustomer = () => {
        // handleSetLoading(true);
        console.log('customerInfo', customerInfo);
        window.parent.postMessage({
            'func': 'showDetailCustomer',
            'message': {
                customer_id : customerInfo.customer_id || null,
                customer_lead_id: customerInfo.customer_lead_id || null,
                ch_customer_id: customerInfo.mapping_id
            }
        }, "*");
    }

    const AvatarLinkedSocial = ({picture, user}) => {
        if (picture)
            return <img className="avatar" src={`${Config.url || ''}/api/images/${picture.shieldedID}/256/${brand}`} alt="avatar" />;
        else
            return <div className="img">{`${user.firstName.substr(0,1)}${user.lastName.substr(0,1)}`}</div>;
    };

    const handleIconChannel = (source) => {
        if(source === 'facebook'){
            return icons.Facebook;
        }
        else if(source === 'zalo'){
            return icons.Zalo;
        }
        else{
            return icons.ClientChat;
        }
    }

    const handleRouteRoom = async (room) => {
        if(room && room._id){
            let elmRoom = document.getElementsByClassName('room');
            for await (let item of elmRoom) {
                item.classList.remove('room-active')
            }

            const target = `/room/${room._id}`;
            if (location.pathname !== target){
                window.location.href = target;
                // await history.replace(target);
            }
            await updateChatNotification(room._id).then().catch(err => console.log(err));
        }
    }

    const handlePreventEvents = () => {
        let className = '';

        if(loading){
            return 'prevent-events';
        }

        if(userChat && userChat.isBlocked){
            return 'prevent-events';
        }

        return className;
    }

    const renderDetectPhone = (detectPhones) => {
        console.log({detectPhones});
        if(detectPhones.length === 0){
            return (
                <div className="customer-info--phone--item text-center">
                    <i>Không có</i>
                </div>
            )
        }

        return detectPhones.map((phone, index) => {
            return (
                <div key={index} className="customer-info--phone--item">
                    <span><FiPhone/></span> <span>{phone}</span>
                </div>
            )
        });
    }

    const getPhoneCustomer = (customerPhone, userPhone) => {
        let arrayPhone = [];

        if(customerPhone){
            arrayPhone.push(customerPhone);
        }
        
        if(customerPhone !== userPhone){
            arrayPhone.push(userPhone);
        }
        
        if(userChat.detect_phones){
            arrayPhone = arrayPhone.concat(userChat.detect_phones);
        }

        return arrayPhone;
    }

    const CustomerInfomation = () => {
        if(isDetectCustomer){ //đã liên kết KH
            const arrayPhone = getPhoneCustomer(customerInfo.phone, userChat.phone);
            const morePhone = arrayPhone.slice(1);

            return (
                <div className="customer-info">
                    {(customerInfo.code || customerInfo.lead_code) && (
                        <div className="title"><Image width="16" src={icons.Code}/> <span>{customerInfo.code || customerInfo.lead_code}</span></div>
                    )}
                    {arrayPhone.length > 0 && (
                        <div className="title customer-info--phone"><Image width="16" src={icons.Phone}/>
                            <Popover content={renderDetectPhone(morePhone)} placement="bottom" title="Số điện thoại khác">
                                <span>{arrayPhone[0]}</span>
                            </Popover>
                        </div>
                    )}
                    {customerInfo.email && (<div className="title"><Image width="16" src={icons.Email}/> <span>{customerInfo.email}</span></div>)}
                    {customerInfo.address && (<div className="title"><Image width="16" src={icons.Address}/> <span>{customerInfo.address}</span></div>)}

                    {customerInfo.related_user && customerInfo.related_user.length > 0 && (
                        <div className="title social-linked">
                            <div><Image width="18" src={icons.SocialLink}/> <span>{i18n.t('linked_account')}</span></div>
                            {customerInfo.related_user.map(item => (
                                <div key={item._id} className="item" title={item.firstName} onClick={() => handleRouteRoom(item.room)}>
                                    <AvatarLinkedSocial picture={item.picture} user={item} />
                                    <Image className="social-icon" width="20" src={handleIconChannel(item.source)}/>
                                </div>
                            ))}
                        </div>
                    )}

                    <div className={`btn-detail-customer text-center mb-2 action-event ${handlePreventEvents()}`} onClick={() => viewDetailCustomer()}>{i18n.t('more_detail')}</div>
                    
                </div>
            )
        }
        else{ //Chưa liên kết KH
            return (
                <div className="customer-info-empty">
                    {userChat.phone ? (
                        <div className="customer-info">
                            {userChat.phone && (
                                <div className="title customer-info--phone"><Image width="16" src={icons.Phone}/>
                                    <Popover content={renderDetectPhone(userChat.detect_phones)} placement="bottom" title="Số điện thoại khác">
                                        <span>{userChat.phone}</span>
                                    </Popover>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="title text-center">{i18n.t('not_info_customer')}</div>
                    )}
                    <div className="text-bold mb-2 w-100 ml-15 text-left">{i18n.t('enter_info_to_search_customer')}</div>
                    <SearchBar setSearchResultCustomer={setSearchResultCustomer} setSearchPage={setSearchPage} showAlertAction={showAlertAction}/>
                </div>
            )
        }
    }

    const generateTooltip = (content) => {
        return <Tooltip>{content}</Tooltip>;
    }

    const UserChatTypeLabel = () => {
        if(!isDetectCustomer){
            return (
                <div className="label-customer-type">
                    <Tag color="default">Chưa liên kết</Tag>
                </div>
            )
        }
        else if(customerInfo){
            if(customerInfo.type === 'customer'){
                return (
                    <div className="label-customer-type">
                        <Tag color="processing">Khách hàng</Tag>
                    </div>
                )
            }
            else if(customerInfo.type === 'cpo'){
                return (
                    <div className="label-customer-type">
                        <Tag color="success">Khách hàng tiềm năng</Tag>
                    </div>
                )
            }
        }
        else{
            return (<></>)
        }
    }

    return (
        <div className="block-ci">
            <SearchPage 
                showAlertAction={showAlertAction} 
                reloadRooms={reloadRooms}
                detectCustomerAction={detectCustomerAction}
                customerInfo={customerInfo}
                searchResultCustomer={searchResultCustomer}
                setSearchResultCustomer={setSearchResultCustomer}
            />
            
            {showModalEditUser && 
            <EditUserModal 
                showModal={showModalEditUser} 
                setShowModal={setShowModalEditUser} 
                setCustomerName={setCustomerName}
                userChat={userChat}
            />}
            <div className="search-header">
                <button className="btn btn-search" onClick={() => setSearchPage('product')}><FiSearch/> {i18n.t('search_product')}</button>
                <button className="btn btn-search" onClick={() => setSearchPage('order')}><FiSearch/> {i18n.t('search_order')}</button>
            </div>
            <div className="customer-info-btn">
                {isDetectCustomer ?
                    (
                    <>
                    <button className={`btn action-update-title action-event ${handlePreventEvents()}`} onClick={() => actionEditCustomer()}><FiEdit size="17"/></button>
                    <OverlayTrigger placement="bottom" overlay={generateTooltip(i18n.t('unlink'))}>
                        <button className={`btn customer-delink action-event ${handlePreventEvents()}`}
                            onClick={() => showConfirmAction(i18n.t('confirm_unlink'))}>
                            <Image width="16" src={icons.DeLink}/>
                        </button>
                    </OverlayTrigger>
                    </>
                ) : (
                    <button className={`btn action-update-title action-event`} onClick={() => actionEditUserChat()}><FiEdit size="16"/></button>
                )}
            </div>
            {loadingCustomerInfo && <Loading/>}
            {!loadingCustomerInfo && (
                <div className="customer-info-wrap">
                    <div className="customer-info-header">
                        <div className="profile">
                            <Picture room={room} userChat={userChat} />
                        </div>
                        <div className="customer-info-head-right">
                            <div className="userchat-name-block">
                                {customerName && <span className="userchat-name">{customerName}</span>}
                                {/* {isDetectCustomer 
                                ? <span className={`action-update-title action-event ${handlePreventEvents()}`} onClick={() => actionEditCustomer()}><FiEdit size="17"/></span>
                                : <span className={`action-update-title action-event`} onClick={() => actionEditUserChat()}><FiEdit size="17"/></span>} */}
                            </div>
                            <UserChatTypeLabel/>
                        </div>
                    </div>
                    <CustomerInfomation/> 
                </div>
            )}
        </div>
    );
};

export default CustomerInfo;
