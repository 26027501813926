import React, {useEffect, useState} from 'react';
import {useGlobal} from "reactn";
import {Link} from "react-router-dom";
import { Table, Row, Col } from 'antd';
import Config from "../../../../config";
import BarChart from "../Chart/BarChart";
import TopCustomer from "../Block/TopCustomer";
import ReportDetailCustomerApi from "../../../../api/report/ReportDetailCustomer";
import ReportChartCustomerApi from "../../../../api/report/ReportChartCustomer";

const CustomerReport = () => {
    const [dataBarChart, setDataBarChart] = useState();
    const [detailCustomer, setDetailCustomer] = useState({});
    const [loading, setLoading] = useState(true);
    const [loadingDetail, setLoadingDetail] = useState(true);
    let brand = useGlobal('brand')[0];

    const selectedTime = useGlobal('selectedTimeReport')[0];
    const selectedChannel = useGlobal('selectedChannelReport')[0];

    useEffect(() => {
        const start_date = selectedTime[0];
        const end_date = selectedTime[1];
        ReportChartCustomerApi({channel_id: selectedChannel, start_date, end_date}).then(res => {
            let data = res.data.data;
            getDataCustomerBarChart(data);
            setLoading(false);
        })
        .catch(err => console.log(err));


        ReportDetailCustomerApi({channel_id: selectedChannel, start_date, end_date}).then(res => {
            let data = res.data.data;
            setDetailCustomer(data);
            setLoadingDetail(false);
        })
        .catch(err => console.log(err));

        
    }, [selectedTime, selectedChannel]);

    const optionCustomerChart = {
        responsive: true,
        indexAxis: 'y',
        plugins: {
            legend: {
                display: false,
                position: 'bottom',
            },
            title: {
                display: false,
                text: 'Chart.js Line Chart',
            },
        },
        animation: {
            duration: 1000 // Animation duration in milliseconds
        },
        scales: {
            x: {
                grid: {
                    display: false, // Ẩn đường kẻ dọc trên trục x
                },
                title: {
                    display: true,
                    text: 'Lượt tương tác' // Label mô tả cho trục x
                },
                beginAtZero: true
            },
            y: {
                grid: {
                    display: false,
                },
                beginAtZero: true,
            }
        },
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        tooltips: {
            enabled: true, // Bật tooltip
        },
        maintainAspectRatio: true,
        // aspectRatio: 1
    };

    const getDataCustomerBarChart = (params) => {
        const data = {
            labels: params?.labels,
            datasets: [
                {
                    label: 'Khách hàng',
                    data: params?.values,
                    borderColor: '#87e8de',
                    backgroundColor: '#87e8de',
                    tension: 0.3,
                    borderWidth: 2,
                    barThickness: 20,
                },
            ],
        }

        setDataBarChart(data);
    };

    const getAvatar = (customer) => {
        let customerName = customer.customer_name;

        if(customer.avatar){
            return (
                <div className='d-flex align-items-center'>
                    <div className='image-box'>
                        <img width="50" src={`${Config.url || ''}/api/images/${customer.avatar}/256/${brand}`} alt=""/>
                    </div>
                    <Link to={`/room/${customer.room_id}`} className="un-link">{customerName}</Link>
                </div>
            )
        }
        else{
            return (
                <div className='d-flex align-items-center'>
                    <div className='image-box'>
                        <div className="img">{customerName.substr(0,1).toUpperCase()}</div>
                    </div>
                    <Link to={`/room/${customer.room_id}`} className="un-link">{customerName}</Link>
                </div>
            )
        }
    }

    const expandedRowRender = (record) => {
        if(loadingDetail) return;

        const columns = [
            {
                title: 'Ngày',
                dataIndex: 'date',
                key: 'date',
            },
            {
                title: 'Số lượng tương tác',
                dataIndex: 'count',
                key: 'count',
            },
        ];

        const items = record.items;
        const data = [];
        let totalMessage = 0;
        for (const i in items) {
            data.push({
                key: i.toString(),
                date: items[i]._id,
                count: items[i].count,
            });

            totalMessage += items[i].count;
        }   

        return <Table 
            columns={columns} 
            dataSource={data} 
            pagination={false}
            bordered
            summary={() => (
                <Table.Summary fixed>
                <Table.Summary.Row>
                    <Table.Summary.Cell index={0}><strong>Tổng</strong></Table.Summary.Cell>
                    <Table.Summary.Cell index={1}><strong>{totalMessage}</strong><br/> tin nhắn</Table.Summary.Cell>
                </Table.Summary.Row>
                </Table.Summary>
            )}
        />;
    };

    const ReportDetailTable = () => {
        if(loadingDetail) return <></>;;

        const columns = [
            {
                title: 'Khách hàng',
                dataIndex: 'customer_name',
                key: 'customer_name',
                render: (dataIndexValue, record) => getAvatar(record)
            },
            {
                title: 'Nguồn',
                dataIndex: 'source',
                key: 'source',
            },
            {
                title: 'Tổng tương tác',
                dataIndex: 'total',
                key: 'total',
            },
            {
                title: 'Ngày tiếp cận',
                dataIndex: 'created_at',
                key: 'created_at',
            },
        ];

        const data = [];
        for (const i in detailCustomer) {
            data.push({
                key: i.toString(),
                room_id: detailCustomer[i].customer.room_id,
                avatar: detailCustomer[i].customer.avatar,
                customer_name: detailCustomer[i].customer.name,
                source: detailCustomer[i].customer.source,
                total: detailCustomer[i].total,
                created_at: detailCustomer[i].customer.created_at,
                items: detailCustomer[i].items
            });
        }   

        return (
            <Table
                columns={columns}
                pagination={false}
                bordered
                expandable={{
                    expandedRowRender,
                    defaultExpandedRowKeys: ['0'],
                }}
                dataSource={data}
            />
        )
    }

    return (
        <>
        <div className='activity-overview' style={{ height: 'unset' }}>
            <Row gutter={24} className="mb-2">
                <Col span={9}>
                    <TopCustomer selectedChannel={selectedChannel} selectedTime={selectedTime}/>
                </Col>
                <Col span={15}>
                    <div className="card">
                            <div className="header">
                                <div className="title-desc">
                                    <div className="title">Biểu đồ tương tác khách hàng</div>
                                    <div className="desc">Biểu đồ top khách hàng tương tác theo thời gian</div>
                                </div>
                                <div className='interaction'></div>
                            </div>
                            <div className="content" style={{ height: 'unset', maxHeight: '484px' }}>
                                <div className="chart-container">
                                    {!loading && <BarChart options={optionCustomerChart} data={dataBarChart}/>}
                                </div>
                            </div>
                    </div>  
                </Col>
            </Row>
        </div>
        <div className="card">
            <div className="header">
                <div className="title-desc">
                    <div className="title">Thống kê chi tiết</div>
                    <div className="desc">Thống kê chi tiết tương tác của khách hàng</div>
                </div>
                <div className='interaction'></div>
            </div>
            <div className="content">
                <div className="chart-container">
                    {!loadingDetail && <ReportDetailTable/>}
                </div>
            </div>
        </div>
        </>
    );
};

export default CustomerReport;
