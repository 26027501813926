import React, {useState, useEffect, useContext} from "react";
import './ChatInfomationTab.sass';
import {useSelector} from "react-redux";
import { AppContext } from '../../../context/AppContext';
import getSessionApi from "../../../actions/getSession";
import { Card, Tag } from 'antd';
import {Tooltip, OverlayTrigger} from "react-bootstrap";
import SessionDetailModal from "./SessionDetailModal";
import moment from "moment";
import {useGlobal} from "reactn";
import {
    ClockCircleOutlined,
    FullscreenExitOutlined
} from '@ant-design/icons';
import {FiArrowRight, FiMessageSquare} from "react-icons/fi";
import MasterConstant from "../../../constants/MasterConstant";

const ChatInfomationTab = ({room}) => {
    const { i18n } = useContext(AppContext);
    const [sessions, setSessions] = useState([]);
    const [offset, setOffset] = useState(0);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [showModalSession, setShowModalSession] = useState(false);
    const setSelectedSessionItem = useGlobal('selectedSessionItem')[1];
    const chatSession = useSelector(state => state.io.chat_session);
    const limit = 5;

    useEffect(() => {
        setOffset(0);
        setShowLoadMore(false);
        getSessionApi(room._id, 0, limit).then(res => {
            if(res.data.error === 0 && res.data.data){
                let result = res.data.data;
                setSessions(result);
                setOffset(e => e + limit);
                if(result.length >= limit) setShowLoadMore(true);
            }
        })
        .catch(err => console.log(err));
    }, [room, chatSession]);

    const getMoreChatSession = () => {
        getSessionApi(room._id, offset, limit).then(res => {
            if(res.data.error === 0 && res.data.data){
                let result = res.data.data;
                setOffset(e => e + limit);
                setSessions(e => [...e, ...result]);
                result.length >= limit ? setShowLoadMore(true) : setShowLoadMore(false);
            }
        })
        .catch(err => console.log(err));
    }

    const getTagSessionStatus = (status) => {
        if(status === 'active'){
            return (<FiMessageSquare color="#52c41a"/>)
        }
        else if(status === 'closed'){
            return (<FiMessageSquare color="#ff0000"/>)
        }
        else if(status === 'pending'){
            return (<Tag color="warning">Pending</Tag>)
        }
        else{
            return (<Tag color="default">Unknown</Tag>)
        }
    }

    const getTimeSession = (session_time) => {
        return moment(session_time).format('DD/MM/YYYY HH:mm');
    }

    const getSessionTitle = (session) => {

        if(session.session_title) return session.session_title;

        if(session.status === 'active'){
            return 'Phiên chat mới';
        }
        else if(session.status === 'closed'){
            let closedBy = '';
            if(session.closed_by === 'system'){
                closedBy = 'Hệ thống';
            }
            if(session.closed_by === 'bot'){
                closedBy = 'Bot';
            }
            if(session.closed_by === 'staff'){
                closedBy = session.closed_by_staff?.full_name || '';
            }

            return `Được đóng bởi ${closedBy}`;
        }
        else if(session.status === 'pending'){
            return 'Phiên chat tạm ngưng';
        }
        else{
            return 'Không có thông tin';
        }
    }

    const handleSessionDetail = (session) => {
        setSelectedSessionItem(session);
        setShowModalSession(true);
    }

    const GenerateTooltip = (content) => {
        return <Tooltip>{content}</Tooltip>;
    }

    return (
        <>
        {showModalSession && <SessionDetailModal room={room} showModal={showModalSession} setShowModal={setShowModalSession}/>}
        <Card size="small" bordered={false} title={i18n.t('session_chat')}>
            <div className="session-chat-block">
                {sessions.length > 0 ? (
                    sessions.map(session => (
                        <div key={session._id} className="session-chat-block--item" onClick={() => handleSessionDetail(session)}>
                            <div className="session-chat-block--item--label">
                                <span>{moment(session.created_at).format('DD')}</span> <br/> <span style={{ fontSize: '10px' }}>Tháng {moment(session.created_at).format('MM')}</span>
                            </div>
                            <div className="session-chat-block--item--value">
                                <div className="session-chat-block--item--value--title">
                                    {getTagSessionStatus(session.status)} 

                                    {session.summary && (
                                        <OverlayTrigger placement="bottom" overlay={GenerateTooltip(i18n.t('summarized'))}>
                                            <Tag bordered={false} size="small" icon={<FullscreenExitOutlined/>} color="success"></Tag>
                                        </OverlayTrigger>
                                    )}  
                                    
                                    <span className="session-chat-block--item--value--title--text">{getSessionTitle(session)}</span>
                                </div>
                                <div className="session-chat-block--item--value--time">
                                    {session.status === 'closed' && <Tag icon={<ClockCircleOutlined />} bordered={false} color="default">
                                        <span style={{ fontSize: '11px' }}>{MasterConstant.calcDiffTime(i18n, session.created_at, session.closed_at)}</span>
                                    </Tag>}
                                    
                                    <span>{getTimeSession(session.created_at)} <FiArrowRight color="#52c41a"/> {session.status === 'active' ? '...' : getTimeSession(session.closed_at)}</span>
                                </div>
                            </div>
                        </div>))
                ) : <div className="text-center">Chưa có thông tin phiên chat</div>}
                {showLoadMore && (<div className="load-more" onClick={() => getMoreChatSession()}>{i18n.t('view_more')}</div>)}
            </div>
        </Card>
        </>
    )
};

export default ChatInfomationTab;
