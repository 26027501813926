import axios from "axios";
import Config from "../../config";

const ReportConversationDetail = params => {
    const {channel_id, keyword = '', start_date, end_date} = params
    return axios({
        method: "POST",
        url: (Config.url || '') + "/api/report/conversation-detail",
        data: { keyword, channel_id, start_date, end_date }
    });
};

export default ReportConversationDetail;
