import React, {useEffect, useState, useContext} from 'react';
import {useGlobal} from "reactn";
import {Link} from "react-router-dom";
import { Table, Spin, Tabs, Tag, Input, Button } from 'antd';
import Config from "../../../../config";
import LineChart from "../Chart/LineChart";
import ModalSummary from "../Block/ModalSummary";
import { AppContext } from '../../../../context/AppContext';
import ReportConversationTimeApi from "../../../../api/report/ReportConversationTime";
import ReportConversationDetailApi from "../../../../api/report/ReportConversationDetail";
import MasterConstant from "../../../../constants/MasterConstant";
import moment from "moment";

const SessionChatReport = () => {
    const { i18n } = useContext(AppContext);
    const [overviewChart, setOverviewChart] = useState();
    const [keyword, setKeyword] = useState("");
    const [chatTotal, setChatTotal] = useState({});
    const [conversationDetail, setConversationDetail] = useState({});
    const [loading, setLoading] = useState(true);
    const [loadingSessionDetail, setLoadingSessionDetail] = useState(true);
    let brand = useGlobal('brand')[0];
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [summaryContent, setSummaryContent] = useState('');

    const selectedTime = useGlobal('selectedTimeReport')[0];
    const selectedChannel = useGlobal('selectedChannelReport')[0];

    useEffect(() => {
        const start_date = selectedTime[0];
        const end_date = selectedTime[1];

        ReportConversationTimeApi({channel_id: selectedChannel, start_date, end_date}).then(res => {
            let data = res.data.data;
            getDataOverViewLineChart(data);
            setChatTotal({
                totalRoom: data.totalRoom, 
                totalSessionActive: data.totalSessionActive,
                totalSessionClosed: data.totalSessionClosed
            });
            setLoading(false);
        })
        .catch(err => console.log(err));

        ReportConversationDetailApi({channel_id: selectedChannel, start_date, end_date}).then(res => {
            let data = res.data.data;
            setConversationDetail(data);
            setLoadingSessionDetail(false);
        })
        .catch(err => console.log(err));
        
    }, [selectedTime, selectedChannel]);

    const optionsOverview = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: false,
                text: 'Chart.js Line Chart',
            },
            tooltip: {
                callbacks: {
                    title: function(context) {
                        let index = context[0].dataIndex;
                        let dataChart = overviewChart;
                        let date = dataChart.allDates[index];
                        date = date.split('-');
                        let day = date[2];
                        let month = date[1];
                        let year = date[0];

                        return `Ngày ${day}/${month}/${year}`;
                    }
                }
            }
        },
        animation: {
            duration: 1000 // Animation duration in milliseconds
        },
        scales: {
            x: {
                grid: {
                    display: false, // Ẩn đường kẻ dọc trên trục x
                },
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Tin nhắn' // Label mô tả cho trục x
                }
            }
        },
        maintainAspectRatio: false,
        aspectRatio: 1
    };

    const getDataOverViewLineChart = (data) => {
        const result = {
            allDates: data.allDates,
            labels: data.labels,
            datasets: [
                {
                    type: 'line',
                    label: 'Phiên chat',
                    data: data?.valueSessions,
                    borderColor: 'rgb(255, 99, 132)',
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    tension: 0.3
                },
                {
                    type: 'bar',
                    label: 'Cuộc hội thoại',
                    data: data?.valueRooms,
                    borderColor: 'rgb(53, 162, 235)',
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    tension: 0.3
                }
            ],
        };

        setOverviewChart(result);
    }

    const getAvatar = (record) => {
        let customerName = record.social_name;

        if(record.avatar){
            return (
                <div className='d-flex align-items-center'>
                    <div className='image-box'>
                        <img width="50" src={`${Config.url || ''}/api/images/${record.avatar}/256/${brand}`} alt=""/>
                    </div>
                    <Link to={`/room/${record.room_id}`} className="un-link">{customerName}</Link>
                </div>
            )
        }
        else{
            return (
                <div className='d-flex align-items-center'>
                    <div className='image-box'>
                        <div className="img">{customerName ? customerName.substr(0,1).toUpperCase() : ""}</div>
                    </div>
                    <Link to={`/room/${record.room_id}`} className="un-link">{customerName}</Link>
                </div>
            )
        }
    }

    const showModal = (summaryContent) => {
        setSummaryContent(summaryContent);
        setIsModalOpen(true);
    };

    const renderViewSummary = (summary) => {
        if(!summary) return <></>;
        return <div className='text-primary cursor-pointer' onClick={() => showModal(summary)}>Xem nội dung</div>
    }

    const ReportDetailConversationTable = () => {
        if(loadingSessionDetail) return <></>;

        const columns = [
            {
                title: 'Tên hội thoại',
                dataIndex: 'social_name',
                key: 'social_name',
                render: (index, record) => getAvatar(record)
            },
            {
                title: 'Tổng tin nhắn',
                dataIndex: 'total_message',
                key: 'total_message',
            },
            {
                title: 'Số phiên chat',
                dataIndex: 'total_session',
                key: 'total_session',
            },
            {
                title: 'Tương tác gần nhất',
                dataIndex: 'last_update',
                key: 'last_update',
            },
        ];

        const data = [];
        for (const i in conversationDetail) {
            data.push({
                key: i.toString(),
                room_id: conversationDetail[i]._id,
                avatar: conversationDetail[i].avatar,
                social_name: conversationDetail[i].social_name,
                total_message: conversationDetail[i].total_message,
                total_session: conversationDetail[i].total_session,
                last_update: conversationDetail[i].last_update_time,
                items: conversationDetail[i].items
            });
        }   

        return (
            <Table
                columns={columns}
                pagination={false}
                bordered
                expandable={{
                    expandedRowRender,
                    defaultExpandAllRows: true
                }}
                dataSource={data}
            />
        )
    }

    const expandedRowRender = (record) => {
        if(loadingSessionDetail) return <></>;

        const columns = [
            {
                title: 'Thông tin phiên',
                dataIndex: 'session_id',
                key: 'session_id',
            },
            {
                title: 'Số lượng tin nhắn',
                dataIndex: 'message_count',
                key: 'message_count',
            },
            {
                title: 'Nhân viên chăm sóc',
                dataIndex: 'staff',
                key: 'staff',
            },
            {
                title: <div>Nội dung tóm tắt <Tag color="processing" size="small">AI</Tag></div>,
                dataIndex: 'summary',
                key: 'summary',
            },
            {
                title: <div>Nhãn trò chuyện <Tag color="processing" size="small">AI</Tag></div>,
                dataIndex: 'tag',
                key: 'tag',
            },
        ];

        const items = record.items;
        const data = [];
        for (const i in items) {
            data.push({
                key: i.toString(),
                session_id: renderSessionId(items[i]),
                message_count: renderMessageCount(items[i]),
                staff: renderStaff(items[i].staff_count),
                summary: renderViewSummary(items[i].summary),
                tag: renderSummaryTag(items[i].ai_tags),
            });
        }   

        return <Table 
            columns={columns} 
            dataSource={data} 
            pagination={false}
            bordered
        />;
    };

    const renderSessionId = (session) => {
        return (
            <div className="session-id-cell">
                <div>{getSessionTitle(session)}</div>
                <div>
                    <span>{moment(session.start_time).format('DD/MM/YYYY HH:mm')} - {moment(session.end_time).format('DD/MM/YYYY HH:mm')}</span>
                    <span style={{ fontSize: '11px', marginLeft: '5px' }}><Tag size="small" color={session.status === 'active' ? 'success' : 'error'}>{MasterConstant.calcDiffTime(i18n, session.start_time, session.end_time)}</Tag></span> 
                </div>
            </div>
        )
    }

    const renderMessageCount = (session) => {
        let countMessageByAdmin = session.count_message_by_admin || 0;
        let countMessageByCustomer = session.count_message_by_customer || 0;
        let total = countMessageByAdmin + countMessageByCustomer;

        return (
            <>
            <div>Từ khách hàng ({countMessageByCustomer})<Tag bordered={false} size="small" color="processing">{((countMessageByCustomer / total) * 100).toFixed(0)}%</Tag></div>
            <div>Từ Chathub ({countMessageByAdmin}) <Tag bordered={false} size="small" color="processing">{((countMessageByAdmin / total) * 100).toFixed(0)}%</Tag></div>
            </>
        )
    }

    const renderSummaryTag = (tags) => {
        if(tags.length === 0) return <></>;

        return tags.map((item, index) => {
            return (
                <div key={index} className='tag-item d-flex'>
                    <div style={{minWidth: '220px', marginBottom: '3px'}}>{item.type}</div>
                    <Tag style={{marginBottom: '5px'}} key={index} color="success">{item.tag}</Tag>
                </div>
            );
        });
    }

    const getSessionTitle = (session) => {

        if(session.session_title) return session.session_title;

        if(session.status === 'active'){
            return 'Phiên chat mới';
        }
        else if(session.status === 'closed'){
            let closedBy = '';
            if(session.closed_by === 'system'){
                closedBy = 'Hệ thống';
            }
            if(session.closed_by === 'bot'){
                closedBy = 'Bot';
            }
            if(session.closed_by === 'staff'){
                closedBy = session.closed_by_staff?.full_name || '';
            }

            return `Được đóng bởi ${closedBy}`;
        }
        else if(session.status === 'pending'){
            return 'Phiên chat tạm ngưng';
        }
        else{
            return 'Không có thông tin';
        }
    }

    const renderStaff = (staffs) => {
        if(staffs.length === 0) return <></>;

        return staffs.map((item, index) => {
            return (<div>{item.staff_name} ({item.count}) <Tag bordered={false} size="small" color="processing">{item.percent}%</Tag></div>)
        });
    }

    const itemTabs = [
        {
            key: '1',
            label: 'Theo cuộc hội thoại',
            children: <Spin tip="Loading..." spinning={loadingSessionDetail}>{loadingSessionDetail ? <div className="loading-content"/> : <ReportDetailConversationTable/>}</Spin>,
        }
    ];

    const onSubmitSearch = () => {
        let text = keyword.trim();

        const start_date = selectedTime[0];
        const end_date = selectedTime[1];

        ReportConversationDetailApi({channel_id: selectedChannel, keyword: text, start_date, end_date}).then(res => {
            let data = res.data.data;
            setConversationDetail(data);
            setLoadingSessionDetail(false);
        })
        .catch(err => console.log(err));
    }

    return (
        <>
        <div className='activity-overview'>
            <div className="card">
                <div className="header">
                    <div className="title-desc">
                        <div className="title">Biểu đồ tổng quan</div>
                        <div className="desc">Thống kê phiên chat và cuộc hội thoại theo ngày</div>
                    </div>
                    <div className='interaction'></div>
                </div>
                <div className="content">
                    <div className="chart-container">
                        <Spin tip="Loading..." spinning={loading}>{loading ? <div className="loading-content"/> : <LineChart options={optionsOverview} data={overviewChart}/>}</Spin>
                    </div>
                </div>
            </div>
        </div>
        <div className='view-ratio-statistic'>
            <div className="view-ratio-item" style={{ maxWidth: '300px' }}>
                <img src="https://pancake.vn/static/images/statistic/page/new-phone-number.svg" className="img-icon" alt=""/>
                <div className='data-ratio'>
                    <div className='ratio-count'>
                        <span>{chatTotal?.totalRoom || 0}</span>
                    </div>
                    <div className='title-ratio'>Cuộc hội thoại</div>
                </div>
            </div>
            <div className="view-ratio-item" style={{ maxWidth: '300px' }}>
                <img src="https://pancake.vn/static/images/statistic/page/old-customer-interact.svg" className="img-icon" alt=""/>
                <div className='data-ratio'>
                    <div className='ratio-count'>
                        <span>{chatTotal?.totalSessionActive || 0}</span>
                    </div>
                    <div className='title-ratio'>Phiên chat đang mở</div>
                </div>
            </div>
            <div className="view-ratio-item" style={{ maxWidth: '300px' }}>
                <img src="https://pancake.vn/static/images/statistic/page/new-customer.svg" className="img-icon" alt=""/>
                <div className='data-ratio'>
                    <div className='ratio-count'>
                        <span>{chatTotal?.totalSessionClosed || 0}</span>
                    </div>
                    <div className='title-ratio'>Phiên chat đã hoàn thành</div>
                </div>
            </div>
        </div>
        <div className="card">
            <div className="header">
                <div className="title-desc">
                    <div className="title">Thống kê chi tiết</div>
                    <div className="desc">Thống kê chi tiết các phiên chat trên trang</div>
                </div>
                <div className='interaction'></div>
            </div>
            <div className="content">
                <div className="input-search text-right">
                    <Input
                        style={{ width: '300px', marginRight: '5px' }}
                        placeholder="Nhập tên đoạn hội thoại để tìm kiếm..."
                        onChange={e => setKeyword(e.target.value)}
                        value={keyword}
                    />
                    <Button type="primary" onClick={() => onSubmitSearch(keyword)}>Tìm kiếm</Button>
                </div>
                <Tabs defaultActiveKey="1" items={itemTabs} />
            </div>
        </div>
        {isModalOpen && <ModalSummary summaryContent={summaryContent}  showModal={isModalOpen} setShowModal={setIsModalOpen}/>}
        </>
    );
};

export default SessionChatReport;
