import React, {useContext} from "react";
import { AppContext } from '../../../../context/AppContext';
import { Modal, Tag } from 'antd';

const ModalSummary = ({summaryContent, showModal, setShowModal}) => {
    const { i18n } = useContext(AppContext);

    return (
        <Modal
            open={showModal}
            id="modal-setting"
            title={i18n.t('session_chat_infomation')}
            onCancel={() => setShowModal(false)}
            width={1000}
            footer={null}
            centered>
                <div className="session-summary--content">
                    <Tag color="default" bordered={false}><div dangerouslySetInnerHTML={{ __html: summaryContent }}></div></Tag>
                </div>
        </Modal>
    )
};

export default ModalSummary;
