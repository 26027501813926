import React, {useEffect, useState, useContext} from 'react';
import { Row, Col } from 'antd';
import BarChart from "../Chart/BarChart";
import PieChart from "../Chart/PieChart";
import TopTag from "../Block/TopTag";
import ReportTagApi from "../../../../api/report/ReportTag";
import {useGlobal} from "reactn";

const TagReport = () => {
    const [dataBarChart, setDataBarChart] = useState();
    const [dataPieChart, setDataPieChart] = useState();
    const [tags, setTags] = useState([]);
    const [loading, setLoading] = useState(true);

    const selectedTime = useGlobal('selectedTimeReport')[0];
    const selectedChannel = useGlobal('selectedChannelReport')[0];

    useEffect(() => {
        const start_date = selectedTime[0];
        const end_date = selectedTime[1];

        ReportTagApi({channel_id: selectedChannel, limit: 5, start_date, end_date}).then(res => {
            let data = res.data.data;
            let dataList = data.lists;
            let dataChart = data.data_chart;
            let dataPie = data.data_pie;
            setTags(dataList);
            getDataTagBarChart(dataChart);
            getDataOverViewLineChart(dataPie);
            setLoading(false);
        })
        .catch(err => console.log(err));

    }, [selectedChannel, selectedTime]);

    const optionTagChart = {
        responsive: true,
        indexAxis: 'y',
        plugins: {
            legend: {
                display: false,
                position: 'bottom',
            },
            title: {
                display: false,
                text: 'Chart.js Line Chart',
            },
        },
        animation: {
            duration: 1000 // Animation duration in milliseconds
        },
        scales: {
            x: {
                grid: {
                    display: false, // Ẩn đường kẻ dọc trên trục x
                },
                title: {
                    display: true,
                    text: 'Lượt gắn thẻ' // Label mô tả cho trục x
                },
                beginAtZero: true
            },
            y: {
                grid: {
                    display: false,
                },
                beginAtZero: true,
            }
        },
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        tooltips: {
            enabled: true, // Bật tooltip
        },
        maintainAspectRatio: true,
        // aspectRatio: 1
    };

    const getDataTagBarChart = (params) => {
        const data = {
            labels: params?.labels,
            datasets: [
                {
                    label: 'Lần gắn thẻ',
                    data: params?.values,
                    borderColor: 'rgb(214,228,255)',
                    backgroundColor: 'rgb(214,228,255)',
                    tension: 0.3,
                    borderWidth: 2,
                    barThickness: 35,
                },
            ],
        }

        setDataBarChart(data);
    };

    const optionsPie = {
        responsive: true,
        plugins: {
            legend: {
                position: 'right',
            },
            title: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    title: function(context) {
                        // let data = context[0].formattedValue;
                        return `Tỉ lệ phản hồi`;
                    },
                    label: function(context) {
                        let label = context.parsed || '';
                        return `${label}%`;
                    }
                }
            }
        },
        animation: {
            duration: 1000 // Animation duration in milliseconds
        },
        maintainAspectRatio: false,
        aspectRatio: 1
    };

    const getDataOverViewLineChart = (data) => {
        const result = {
            labels: data.labels,
            datasets: [
                {
                    label: '# of Votes',
                    data: data.percentValues,
                    backgroundColor: [
                        'rgb(38,115,255)',
                        'rgb(100,110,144)',
                        'rgb(25,172,93)',
                        'rgb(155,68,186)',
                        'rgb(81,191,255)',
                        'rgb(189,39,39)',
                    ],
                    borderColor: [
                        '#fff',
                        '#fff',
                        '#fff',
                        '#fff',
                        '#fff',
                        '#fff',
                    ],
                    borderWidth: 2,
                },
            ],
        };

        setDataPieChart(result);
    }

    return (
        <>
        <div className='activity-overview' style={{ height: 'unset' }}>
            <Row gutter={24} className="mb-2">
                <Col span={9}>
                    <TopTag tags={tags}/>
                </Col>
                <Col span={15}>
                    <div className="card">
                        <div className="header">
                            <div className="title-desc">
                                <div className="title">Tỉ lệ các thẻ được gắn</div>
                                <div className="desc">Tỉ lệ các thẻ được gắn trong khoảng thời gian</div>
                            </div>
                            <div className='interaction'></div>
                        </div>
                        <div className="content">
                            <div className="chart-container">
                                {!loading && <PieChart options={optionsPie} data={dataPieChart}/>}
                            </div>
                        </div>
                    </div> 
                </Col>
            </Row>
        </div>
        <div className="card">
            <div className="header">
                <div className="title-desc">
                    <div className="title">Biểu đồ thẻ hội thoại</div>
                    <div className="desc">Biểu đồ top thẻ hội thoại được gắn</div>
                </div>
                <div className='interaction'></div>
            </div>
            <div className="content" style={{ height: 'unset' }}>
                <div className="chart-container">
                    {!loading && <BarChart options={optionTagChart} data={dataBarChart}/>}
                </div>
            </div>
        </div> 
        </>
    );
};

export default TagReport;
